<template>
  <page-content>

    <common-table
      ref="table"
      path="device"
      search-placeholder="搜索"
      :columns="columns"
      :queryParams="queryParams"
      @reset="reset">


      <template slot="icon" slot-scope="{record}">
        <a-avatar shape="square" :src="record.devicePicture" style="background: #F8F8F8;margin-right: 10px"/>
        {{record.productName}}
      </template>
      <template slot="status" slot-scope="{record}">
        <a-tag v-if="record.status==1" color="green">在线</a-tag>
        <a-tag v-else color="cyan">离线</a-tag>
      </template>
      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>

        <a-button
                style="margin-right: 4px"
                size="small"
                icon="video-camera"
                type="primary"
                @click="clickCamera(record)"
                ghost></a-button>

        <action-delete @click="remove(record)"></action-delete>

      </template>

    </common-table>


  </page-content>

</template>
<script>

  import TimeUtils from '@/utils/TimeUtils'
  import StatisticsDataItem from '@/components/common/StatisticsDataItem'

  export default {
    components:{StatisticsDataItem},
    data () {
      return {
        queryParams: {
          productKey: '',
          address: ''
        },
        productList: [],
        statistics: {
          total: 0,
          totalOnline: 0,
          totalToday: 0,
          totalWeek: 0
        },
      }
    },
    created () {

      this.productKey = this.$route.params.productkey || ''
      this.getProductList()

      this.$get('device/overview', {}).then((r) => {
        this.statistics = r.data
      })
    },
    computed: {
      columns () {
        let productFilter = [{text: '全部', value: ''}]
        this.productList.forEach(item=>{
          productFilter.push({text: item.name, value: item.productKey})
        })
        return [
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: {customRender: 'status'},
            filters: [
              {text: '全部', value: '0'},
              {text: '在线', value: '1'},
              {text: '离线', value: '3'}
            ],
            filterMultiple: false,
            width: 80
          },
          {
            title: '产品',
            dataIndex: 'productKey',
            scopedSlots: {customRender: 'icon'},
            filters: productFilter,
            filterMultiple: false,
            width: 160
          },

          // {
          //   title: '设备ID',
          //   dataIndex: 'iotId'
          // },
          {
            title: '设备MAC',
            dataIndex: 'mac'
          },
          {
            title: '设备名称',
            dataIndex: 'nickname'
          },

          {
            title: '地址',
            dataIndex: 'address'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 120
          }
        ]
      }
    },
    methods: {

      productChange (productKey) {
        this.queryParams.productKey = productKey
        this.getList()
      },

      reset () {
        this.queryParams = {
          productKey: '',
          address: ''
        }
      },

      view (record) {
        this.$router.push(`/device/info/${record.iotId}`)
      },
      remove(record){
        let that = this
        this.$confirm({
          title: '是否删除该设备?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('device/' + record.iotId).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      clickCamera(record){
        this.$router.push(`/device/info/camera/${record.iotId}`)
      },

      getList () {
        this.$refs.table.getData()
      },
      getProductList () {
        this.$get('product').then((r) => {
          let data = r.data
          this.productList = data
        })
      },

    }
  }
</script>
<style lang="less" scoped>
  .data-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
</style>
